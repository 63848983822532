<template>
  <div class="activation_code_page">
    <div class="main">
      <!-- 激活 -->
      <div class="activation_group">
        <div class="main_title_group">产品激活</div>
        <div class="activation_box">
          <div class="text">激活码：</div>
          <input
            type="text"
            class="activation_code"
            placeholder="请输入激活码激活产品"
            v-model="activation_code"
            @keyup.enter="confirmActivation()"
          >
          <button
            class="btn button cp"
            v-preventReClick
            @click="confirmActivation()"
            v-show="activation_code !== ''"
          >确认</button>
        </div>
      </div>
      <!-- 记录 -->
      <div class="record_group">
        <div class="main_title_group">激活记录</div>
        <div class="record_list_wrapper">
          <div class="record_list_group_wrapper">
            <div class="record_list_group"
              v-if="record_list && record_list.length"
            >
              <div class="title_group">
                <div class="title time">日期</div>
                <div class="title code">激活码</div>
                <div class="title type_list">产品</div>
                <div class="title content_list">激活内容</div>
              </div>

              <div class="list_group"
                v-for="(item,index) in record_list"
                :key="index"
              >
                <div class="list time">{{$moment(item.updateTime).format('YYYY-MM-DD HH:mm:ss')}}</div>
                <div class="list code">{{item.code}}</div>
                <div class="list content_list">
                  <div class="group"
                    v-if="item.vipdays"
                  >
                    <div class="type">会员权益</div>
                    <div class="content">vip会员{{item.vipdays}}天</div>
                  </div>
                  <div class="group"
                    v-if="item.packageNames"
                  >
                    <div class="type">课程</div>
                    <div class="content">{{item.packageNames}}</div>
                  </div>
                  <div class="group"
                    v-if="item.teacheNames"
                  >
                    <div class="type">模板</div>
                    <div class="content">{{item.teacheNames}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      activation_code: '', // 激活码
      record_list: null,
    }
  },
  computed:{
    acti_status(){
      return this.$store.state.acti_status;
    },
  },
  watch: {
    acti_status: {
      handler: async function(val){
        if(val === 'done'){
          await this.getRecordList();
        }
      },
    },
  },
  methods: {
    /**
     * @description: 确认激活码信息BTN
     * @return {*}
     */
    async confirmActivation(){
      let {activation_code} = this;
      let res;
      let data = {
        code: activation_code,
      };
      try{
        res = await this.$http.request('/project/codePachageTasklist', data);
        if(res.message === 'success'){

          let contents = res.contents;
          const {code} = contents;
          if(code.code && code.userId){
            this.$notice({desc: '该激活码已经被使用过了', duration: '1500'})
            return;
          }
          this.$store.commit('set_acti_confirm_data', contents);
          this.$store.commit('set_acti_code', activation_code);
          this.$store.commit('set_switch_acti_confirm', true); // 打开窗口

        }else{
          this.$notice({desc: res.describe, duration: '1500'})
        }
      }catch(err){
        this.$notice({desc: '激活码信息查询失败', duration: '1500'})
      }
    },
    /**
     * @description: 获取记录列表
     * @return {*}
     */
    async getRecordList(){
      let data = {};
      let res;
      try{
        res = await this.$http.request('/project/codelist', data);
        if(res.contents.list){
          this.$set(this, 'record_list', res.contents.list);
        }
      }catch(err){
        // console.log(err);
      }
      this.$store.commit('set_acti_status', '');
    },
    async init(){
      await this.getRecordList();
    },
  },
  async mounted(){
    this.init();
  }
}
</script>
<style lang="scss" scoped>
.activation_code_page{
  @include bbox;
  padding: 20px;
  width: 100%;
  height: 100%;
  .main{
    @include flex;
    @include bbox;
    flex-direction: column;
    align-items: stretch;
    background-color: #fff;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 10px 0 rgba(0, 84, 167, 0.06);
    border-radius: 10px;
    // 标题
    .main_title_group{
      @include bbox;
      font-size: 18px;
      color: $main_blue;
      padding-top: 30px;
      padding-left: 24px;
      line-height: 20px;
      position: relative;
      width: 100%;

      &::before{
        content: '';
        display: block;
        position: absolute;
        left:0;
        bottom:0;
        width: 4px;
        height: 20px;
        background-color: $main_blue;
      }
    }
    // 激活
    .activation_group{
      box-shadow: 0 4px 10px 0 rgba(0, 84, 167, 0.06);
      padding-bottom: 40px;
      flex-shrink: 0;
      .activation_box{
        @include flex;
        @include bbox;
        padding: {
          left: 24px;
          top: 30px;
        }
        .text{
          font-size: 16px;
          color:#333;
        }
        .activation_code{
          @include bbox;
          padding: 0 20px;
          width: 400px;
          height: 46px;
          background: #FBFBFB;
          border: 1px solid #F0F0F0;
          border-radius: 4px;
          color: #333;
          outline: none;
        }
        .btn{
          margin-left: 20px;
          background-color: $main_blue;
          color:#fff;
          line-height: 46px;
          border-radius: 4px;
          font-size: 16px;
          padding: 0 28px;
        }
      }

    }
    // 记录
    .record_group{
      @include flex;
      @include bbox;
      flex-direction: column;
      width: 100%;
      flex: 1;
      height: 100%;
      overflow: hidden;
      .record_list_wrapper{
        @include flex;
        @include bbox;
        flex-direction: column;
        padding: 20px;
        flex:1;
        height: 100%;
        width: 100%;
        // overflow-y: auto;
        overflow: hidden;
      }
      .record_list_group_wrapper{
        // flex: 1;
        // height: 100%;
        width: 100%;
        border: 2px solid #e5eef6;
        overflow-y: auto;
      }
      .record_list_group{
        .title_group{
          @include flex;
          background-color: #fff;
          font-size: 18px;
          line-height: 60px;
          font-weight: bold;
          text-align: center;
          position: sticky;
          top:0;
          left:0;
          .title{
            border: {
              right: 1px solid #e5eef6;
              bottom: 1px solid #e5eef6;
            }
            &:last-child{
              border-right: none;
            }
          }
        }
        // .group:nth-child(odd){
        //   background-color: #E5EEF6;
        // }
        .list_group{
          @include flex;
          font-size: 16px;
          color: #333;
          text-align: center;
          align-items: stretch;
          border-bottom: 2px solid #e5eef6;
          &:last-child{
            border-bottom: none;
          }
          .time, .code{
            @include flex;
            align-items: center;
            justify-content: center;
          }
          .list{
            border: {
              right: 1px solid #e5eef6;
              // bottom: 1px solid #e5eef6;
            }
            &:last-child{
              border-right: none;
            }
          }
          .content_list{
            line-height: 50px;
            min-height: 50px;
            .group{
              @include flex;
              border-bottom: 1px solid #e5eef6;
              &:last-child{
                border-bottom: none;
              }
              &:hover{
                @include transition;
                background-color: #e5eef6;
              }

              .type{
                @include bbox;
                border-right: 1px solid #e5eef6;
                width: 150px;
              }
              .content{
                @include bbox;
                padding: 0 20px;
                width: 100%;
                flex: 1;
                text-align: justify;
                text-align-last: left;
              }
            }
          }
        }
        .time{
          width:180px;
        }
        .code{
          width: 180px;
        }
        .type_list{
          width: 150px;
        }
        .content_list{
          width: 100%;
          flex: 1;
        }
      }
    }
  }
}
</style>
